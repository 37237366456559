<template>
  <div :class="`select${multi?' multi':' single'}`" ref="select">
    <div class="dropdown-toggle" ref="button_drop" data-bs-toggle="dropdown"
         data-bs-auto-close="outside"
         aria-expanded="false"
         :style="`min-width: ${width}px`"
    >
      <button type="button" v-on:click="changeError()">
        <span class="placeholder" v-if="(multi&&!modelValue?.length) || (!multi && (modelValue===undefined || modelValue===null))">{{ name }}</span>
        <template v-if="!multi">
          <span class="value" v-if="modelValue !== undefined && modelValue !== null">{{ getModelName(modelValue) }}</span>
        </template>
        <template v-else>
          <div v-if="modelValue && modelValue.length" class="tags">
          <span class="tag" v-for="m in modelValue">
            {{ getModelName(m) }} <a href="" v-on:click.prevent="setValue(getOption(m))"><i class="bi bi-x"></i></a>
          </span>
          </div>
        </template>
        <i class="bi bi-chevron-down"></i>
      </button>


    </div>
    <ul class="dropdown-menu" ref="dropdown">

      <li><span class="dropdown-item"><input v-model="search" type="text"/></span></li>
      <li v-if="!required_val"><a href="" class="dropdown-item" v-on:click.prevent="emptyValue()"></a></li>
      <li class="dropdown-option"><a href="" v-for="option in calc_options"
                                     :class="`dropdown-item${getActive(option)?' active':''}`"
                                     v-on:click.prevent="setValue(option)">{{
          option[fname]?.replace(new RegExp(" $"), "_")
        }}</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MultiSelect",
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    modelValue: undefined,
    options: Object,
    multi: Boolean,
    name: String,
    model_error: undefined,
    max: Number,
    only_value: Boolean,
    required: Boolean,
    addValue: Function,
    removeValue: Function,
    field_id: String,
    field_name: String,
    changeFunc: Function
  },
  data() {
    return {
      search: "",
      fid: this.field_id || "id",
      fname: this.field_name || "name",
      required_val: this.required || false,
      width: null
    }
  },
  mounted() {
    this.width = this.$refs.dropdown.offsetWidth;
    this.$refs.button_drop.addEventListener('hidden.bs.dropdown', () => {
      this.$refs.select.classList.remove("open")
    })
    this.$refs.button_drop.addEventListener('show.bs.dropdown', () => {
      this.$refs.select.classList.add("open")
    })
  },
  computed: {
    calc_options() {
      let qs = this.options;
      if (this.search) qs = qs.filter((option) => {
        return (option[this.fname].toLowerCase().indexOf(this.search.toLowerCase()) + 1)
      })
      return qs
    }
  },
  methods: {
    emptyValue() {
      this.$emit('update:modelValue', this.multi ? [] : this.only_value ? null : {});
    },
    setValue(option) {
      if (this.multi) {
        let value = option;
        let index = 0, current = this.modelValue.filter(x => (this.only_value ? x : x[this.fid]) == value[this.fid]);
        if (this.only_value) value = option[this.fid];
        if (current) current = current[0];

        if (current) {
          index = this.modelValue.indexOf(current);
          if (index + 1) this.modelValue.splice(index, 1);
          if (this.removeValue) this.removeValue(current)
        } else {
          if (this.max && this.modelValue.length >= this.max) this.$emit('update:model_error', `Максимальное количество выбранных элементов ${this.max}`)
          else {
            this.modelValue.push(value)
            if (this.addValue) this.addValue(value)
          }
        }
      } else {
        this.$emit('update:modelValue', this.only_value ? option.id : option);
        if (this.addValue) this.addValue(this.only_value ? option.id : option)
        this.$refs['button_drop'].click()
      }

    },
    changeError() {
      this.$emit('update:model_error', false)
    },
    getActive(option) {
      if (this.multi && this.modelValue) {
        return !!this.modelValue.filter(x => (this.only_value ? x : x[this.fid]) == option[this.fid]).length
      } else {
        return !!this.modelValue && ((this.only_value ? this.modelValue : this.modelValue[this.fid]) == option[this.fid])
      }
    },
    getModelName(option) {
      if (this.only_value) {
        let name = option;
        let opt_value = this.options.filter(x => x[this.fid] == option)
        if (opt_value.length) name = opt_value[0][this.fname]
        return name
      }
      return option[this.fname];
    },
    getOption(value) {
      let option = value;
      if (this.only_value) {
        let opt_value = this.options.filter(x => x[this.fid] == option)
        if (opt_value.length) option = opt_value[0]
        return option
      }
      return option;
    }
  }
}
</script>

<style scoped lang="scss">
.form-error{
  .select{
    border-color: #fb8d7b;
  }
}
.negative .select button .value{
  color: #d50004;
}
.select {
  width: fit-content;
  border-radius: 10px;
  border: 1px solid rgba(139, 139, 139, 1);
  display: flex;
  align-items: center;
  position: relative;
  min-height: 35px;


  &.open {
    z-index: 100000;
  }


  button {
    width: 100%;
    border: none;
    background: transparent;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    padding: 0;
  }

  .placeholder {
    color: rgba(139, 139, 139, 1);
  }

  .value {
    font-weight: 500;
  }

  .dropdown-toggle {
    padding: 5px 20px;

    &::after {
      display: none;
    }

  }

  .dropdown-option {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .dropdown-item {
    min-height: 25px;

    &:active, &:focus, &:hover, &.active {
      background: rgba(234, 234, 234, 1);
      border-radius: 10px;
      text-decoration: none;
      color: inherit;
    }
  }

  .dropdown-menu {
    width: max-content;
    border-radius: 0 0 15px 15px;
    //transition: 0.5s;
    display: block !important;
    opacity: 0;
    pointer-events: none;

    &.show {
      opacity: 1;
      width: 100%;
      pointer-events: inherit;
    }

    input {
      width: 100%;
    }
  }

  .tags {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .tag {
      display: flex;
      gap: 8px;
      //padding: 5px;
      font-weight: 500;

      &:hover {
        background: rgba(234, 234, 234, 1);
        border-radius: 5px;
      }

      i {
        background: rgba(254, 66, 45, 1);
        color: #fff;
        border-radius: 3px;
        display: flex;
      }
    }
  }

}
</style>
